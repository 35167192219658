<template>
  <div class="container_box details_box_styles">
    <!--头部信息区域-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <Row type="flex" :gutter="gutterItem">
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ configCharacters + 'SPU：' }}</span>
            <span class="basic_txt" :class="skuInfo.ymsSpu === null ? 'red_txt' : ''">{{ skuInfo.ymsSpu ? skuInfo.ymsSpu : $t('key1000393') }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ configCharacters + $t('key1000394') }}</span>
            <span class="basic_txt">{{ skuInfo.ymsProductCategoryName }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000395') }}</span>
            <span class="basic_txt">{{ skuInfo.shelveStatusTxt }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000396') }}</span>
            <span class="basic_txt" :title="skuInfo.name">{{ skuInfo.name }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000397') }}</span>
            <span>{{ skuInfo.spu }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000398') }}</span>
            <span>{{ skuInfo.skuNumber }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000399') }}</span>
            <span>{{ skuInfo.productGoodsTotalNumber }}</span>
          </Col>
        </Row>
      </Card>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table ref="tableDom" highlight-row border max-height="650" :loading="tableLoading" :columns="tableColumns" :data="tableData"></Table>
    </div>
    <!-- 操作日志 -->
    <operationLogModal ref="operationLogTalg" :productGoodsId="productGoodsId" operationType="sku"></operationLogModal>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import operationLogModal from "@/components/common/operationLogModal";
import {getBatchCategoryInfoData, setConfigCharacters} from "@/utils/common";

export default {
  name: "viewSku",
  mixins: [Mixin],
  data() {
    return {
      ItemCol: 6,
      pageParamsStatus: false,
      productId: null,
      skuInfo: {},
      tableData: [],
      productGoodsId: null,
      salesStatusList: [
        {value: 0, title: alias2f085dd487c1465383484787b29fef2c.t('key1000400')},
        {value: 1, title: alias2f085dd487c1465383484787b29fef2c.t('key1000401')},
        {value: 2, title: alias2f085dd487c1465383484787b29fef2c.t('key1000344')}
      ],
      productColorImageList: [],
    };
  },
  computed: {
    tableColumns() {
      let v = this;
      let columnsList = [];
      let columns = [
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000160'),
          key: 'pictureUrl',
          align: 'center',
          width: 140,
          render(h, params) {
            let valueList = [];
            let productGoodsSpecifications = params.row.productGoodsSpecifications || [];
            if (productGoodsSpecifications.length > 0) {
              valueList = productGoodsSpecifications.map((item) => {
                return item.value;
              })
            }
            if (v.productColorImageList.length > 0) {
              let list = v.productColorImageList.filter((ele) => {
                return valueList.includes(ele.color)
              });
              if (list && list.length > 0) {
                let path = list[0].path;
                return v.tableImg(h, params, null, path);
              }
            }
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000402'),
          key: 'sku',
          align: 'center',
          minWidth: 180
        },
        {
          title: `${setConfigCharacters}SKU`,
          key: 'ymsSku',
          align: 'center',
          minWidth: 180
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000403'),
          width: 300,
          align: 'center',
          render(h, params) {
            if (params.row.productGoodsSpecifications) {
              return h('div', {
                style: {
                  color: 'green'
                }
              }, params.row.productGoodsSpecifications.map(i => {
                return h('p', i.name + ':' + i.value);
              }));
            }
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000404'),
          key: 'status',
          align: 'center',
          minWidth: 160,
          render: (h, params) => {
            let status = params.row.status;
            let title = '';
            v.salesStatusList.map((item) => {
              if (item.value === status) {
                title = item.title;
              }
            });
            return h('div', title);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000207'),
          key: 'allottedNumber',
          align: 'center',
          minWidth: 130,
          render(h, params) {
            return h('span', params.row.allottedNumber || 0)
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000208'),
          key: 'availableNumber',
          align: 'center',
          minWidth: 130,
          render(h, params) {
            return h('span', params.row.availableNumber || 0)
          }
        }
      ];
      let suggestPrice = [{
        title: alias2f085dd487c1465383484787b29fef2c.t('key1000405'),
        key: 'suggestPrice',
        minWidth: 150,
        align: 'center',
        render(h, params) {
          return h('div', [
            h('div', params.row.suggestPrice),
            h('div', '(' + v.currencyState + ')'),
          ]);
        }
      }];
      let opts = [{
        title: alias2f085dd487c1465383484787b29fef2c.t('key1000278'),
        key: 'options',
        width: 200,
        fixed: 'right',
        align: 'center',
        render: (h, params) => {
          let status = params.row.status;
          return h('optsButtonList', {
            props: {
              operationList: [
                {
                  text: alias2f085dd487c1465383484787b29fef2c.t('key1000406'), // 运营端先暂时屏蔽掉上架功能
                  show: status === 2 && v.getPermission('productGoods_updateStatus') && v.system === 'supplier',
                  clickFn: () => {
                    if (v.systemTalg) {
                      v.groundingSkuBtn(params.row.productGoodsId, 1);
                    } else {
                      v.groundingSkuBtn(params.row.ymsProductGoodsId, 1);
                    }
                  }
                },
                {
                  text: alias2f085dd487c1465383484787b29fef2c.t('key1000344'),
                  show: status === 1 && v.getPermission('productGoods_updateStatus'),
                  clickFn: () => {
                    if (v.systemTalg) {
                      v.groundingSkuBtn(params.row.productGoodsId, 2);
                    } else {
                      v.groundingSkuBtn(params.row.ymsProductGoodsId, 2);
                    }
                  }
                },
                {
                  text: alias2f085dd487c1465383484787b29fef2c.t('key1000215'),
                  show: v.getPermission('productGoodsOperationLog_query'),
                  clickFn: () => {
                    if (v.systemTalg) {
                      v.productGoodsId = params.row.productGoodsId;
                    } else {
                      v.productGoodsId = params.row.ymsProductGoodsId;
                    }
                    v.$refs['operationLogTalg'].initializeData(v.productGoodsId);
                  }
                },
                {
                  text: alias2f085dd487c1465383484787b29fef2c.t('key1000407'),
                  show: v.getPermission('productGoods_appendProductGoodsExpressBill') && v.systemTalg,
                  clickFn: () => {
                    v.printBtn(params.row.productGoodsId, 0)
                  }
                },
                {
                  text: alias2f085dd487c1465383484787b29fef2c.t('key1000408'),
                  show: v.getPermission('productGoods_appendProductGoodsExpressBill') && v.systemTalg,
                  clickFn: () => {
                    v.printBtn(params.row.productGoodsId, 1)
                  }
                },
                {
                  text: alias2f085dd487c1465383484787b29fef2c.t('key1000091'),
                  show: status === 2 && v.systemTalg && v.getPermission('productGoods_delete'),
                  clickFn: () => {
                    v.deleteSkuBtn(params.row.productGoodsId);
                  }
                }
              ]
            },
            on: {
              setWidth(value) {
                if (value) {
                  v.optionBtnWidth.push(value);
                  params.column.width = Math.max(...v.optionBtnWidth);
                  v.$forceUpdate();
                }
              }
            }
          });
        }
      }];
      if (v.system === 'supplier') {
        columnsList = [...columns, ...suggestPrice, ...opts];
      } else {
        columnsList = [...columns, ...opts];
      }
      v.$forceUpdate();
      return columnsList;
    }
  },
  created() {
    let query = this.$route.query;
    if (query) {
      this.productId = query.productId;
      this.getSkuData(this.productId);
    }
  },
  methods: {
    // 上架、停售sku
    groundingSkuBtn(productGoodsId, status) {
      let v = this;
      let query = {
        productGoodsId: productGoodsId,
        status: status
      };
      v.axios.put(api.put_productGoods_updateStatus, query, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(response => {
        if (response.data.code === 0) {
          this.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
          v.getSkuData(this.productId);
        }
      });
    },
    // 删除sku
    deleteSkuBtn(productGoodsId) {
      let v = this;
      v.$Modal.confirm({
        title: alias2f085dd487c1465383484787b29fef2c.t('key1000409'),
        onOk: () => {
          v.axios.delete(api.delete_productGoods + productGoodsId, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(response => {
            if (response.data.code === 0) {
              this.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
              v.getSkuData(this.productId);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    // 获取查看sku的数据
    getSkuData(productId) {
      let v = this;
      v.optionBtnWidth = [];
      v.tableData = [];
      v.skuList = [];
      v.skuList = [];
      v.skuList = [];
      v.skuList = [];
      let config = {
        headers: {
          'Language': v.languagesVal
        },
        loading: true
      }
      if (v.getPermission('productInfo_queryForSkuList')) {
        v.axios.get(api.get_productInfo_queryForSkuList + productId, config).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              // 云卖分类
              let ymsProductCategoryId = data.ymsProductCategoryId;
              let url = v.system === 'yms' ? api.post_ymsProductCategory_queryByYmsProductCategoryIds : api.post_ymsProductCategory_api_queryDetailByCategoryIds;
              getBatchCategoryInfoData(url, null, [ymsProductCategoryId], true).then((newData) => {
                if (newData.length > 0) {
                  newData.map((item) => {
                    if (item.ymsProductCategoryId === ymsProductCategoryId) {
                      let txt = item.productCategoryNavigation.split('->');
                      data.ymsProductCategoryName = txt.join(' / ');
                    }
                  });
                }
              });
              // 商品状态
              let shelveStatus = data.shelveStatus;
              v.salesStatusList.map((item) => {
                if (item.value === shelveStatus) {
                  data.shelveStatusTxt = item.title;
                }
              });
              v.skuInfo = data;
              v.productColorImageList = data.productColorImageList || [];
              let productGoodsList = data.productGoodsList || [];
              if (productGoodsList.length > 0) {
                data.productGoodsList.map((item) => {
                  item.suggestPrice = v.formatCurrency(item.suggestPrice);
                });
                v.tableData = data.productGoodsList;
                v.updateTableDom('tableDom');
              }
            }
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 打印条码、打印二维码
    printBtn(productGoodsId, type) {
      let v = this;
      let list = [{
        productGoodsId: productGoodsId,
        quantity: 1,
        type: type // 0-条形码  1-二维码
      }];
      v.axios.post(api.post_productGoods_appendProductGoodsExpressBill, list, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then((response) => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.commonPrint(data, '1');
        } else if (response.data.code === 220026) {
          v.$Modal.warning({
            title: alias2f085dd487c1465383484787b29fef2c.t('key1000410'),
            content: alias2f085dd487c1465383484787b29fef2c.t('key1000411'),
            okText: alias2f085dd487c1465383484787b29fef2c.t('key1000138')
          });
        }
      });
    }
  },
  components: {
    operationLogModal
  }
};
</script>

<style lang="less" scoped>
.basic_txt {
  max-width: 320px !important;
}

.red_txt {
  color: #E41E09;
}

</style>
